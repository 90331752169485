#login-button {
  border: 2px solid black;
  box-shadow: 4px 4px #000000;
  background-color: #fff;
}

#login-button:hover {
  background-color: #5fcfe3;
}

#login-button:active {
  transform: translateY(2px) translateX(2px);
  box-shadow: 2px 2px #000000;

}

#navLink {
  display: inline-block;
  position: relative;
  color: #000000;
}

#navLink:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

#navLink:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#hamburger-button {
  color: black;
  border: none;
  outline: none;
  box-shadow: none;
}

#hamburger-button:active {
  border: none;
  outline: none;
  box-shadow: none;
}