input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 2.3em;
  height: 2.3em;
  border: 0.15em solid #000;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 1.15em;
  height: 1.15em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: #5fcfe3;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}


.radio-yes {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 2px solid black;
}


.radio-yes:active {
  transform: translateY(2px) translateX(2px);
}

.radio-no {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 2px solid black;
}


.radio-no:active {
  transform: translateY(2px) translateX(2px);
}