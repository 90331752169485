#select-field {
  width: 100%;
  border: 2px solid black;
  padding: 3px 8px 3px 8px;
  border-radius: 5px;
}

#select-field:focus {
  outline: solid #5fcfe3;
}
