.typahead-menu {
    border: 2px solid black;
    box-shadow: 4px 4px #000000;
    max-height: 30vh;
}

#submit-button {
    border: 2px solid black;
    box-shadow: 4px 4px #000000;
    background-color: #e35f8d;
    color: black;
}

#submit-button:active {
    transform: translateY(2px) translateX(2px);
    box-shadow: 2px 2px #000000;
}