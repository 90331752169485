#rating-button {
  height: 50px;
  width: 50px;
  background-color: #5fcfe3;
  border: 2px solid black;
  box-shadow: 3px 3px #000000;
}

#rating-button:hover {
  background-color: rgba(95, 207, 227, .8);
}

#rating-button:active {
  transform: translateY(2px) translateX(2px);
  box-shadow: 2px 2px #000000;
}