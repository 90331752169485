.input-field {
  width: 100%;
  border: 2px solid black;
  padding: 3px 8px 3px 8px;
  border-radius: 5px;
  min-height: 5vh;
}

.input-field:focus {
  outline: solid #5fcfe3;
}
