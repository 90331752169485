#slider-1:hover {
  background-color: #9e27b5;
}

#slider-2:hover {
  background-color: #8e51c0;
}

#slider-3:hover {
  background-color: #7e7bcc;
}

#slider-4:hover {
  background-color: #6ea5d7;
}

#slider-5:hover {
  background-color: #5fcfe3;
}