#radio-yes {
  width: 44px;
  height: 44px;
  border: 2px solid black;
  appearance: none;
}

#radio-yes:hover {
  background-color: green;
}

#radio-no {
  width: 44px;
  height: 44px;
  border: 2px solid black;
  appearance: none;
}

#radio-no:hover {
  background-color: red;
}

.slider {
  border: 2px solid black;
  border-radius: 6px;
  height: 40px;
  width: 80px;
  background-color: #E4E4E4;
}

.slider:hover {

}

#slider-1:hover {
  background-color: #9e27b5;
}

#slider-2:hover {
  background-color: #8e51c0;
}

#slider-3:hover {
  background-color: #7e7bcc;
}

#slider-4:hover {
  background-color: #6ea5d7;
}

#slider-5:hover {
  background-color: #5fcfe3;
}